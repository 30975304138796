var site = site || {};

site.template = site.template || {};
var generic = generic || {};

(function ($, Drupal) {
  Drupal.behaviors.whatsNewitemV1 = {
    attach: function (context, settings) {
      $('.mm-whats-new-item-mobile-v1.is-img-item').each(function () {
        $this = $(this);
        var img = new Image();

        img.src = $this.data('img');
        $this.append($(img));
      });
    }
  };
}(jQuery, Drupal));
